body,
label,
.checkbox label {
  font-weight: 300;
}
.jqtree-element {
  min-height: 30px;
  /*padding: 3px;*/
  padding: 3px 0;
}
table.dataTable tbody tr.selected {
  background-color: #97BDD6 !important;
  background: -webkit-gradient(linear, left top, left bottom, from(#BEE0F5), to(#89AFCA)) !important;
  background: -moz-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -ms-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  background: -o-linear-gradient(top, #BEE0F5, #89AFCA) !important;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.7) !important;
}
.select2-container .select2-selection--single {
  height: 34px !important;
}
pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}
